<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-30 19:03:17
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="install">
      <heada></heada>
      <div class="con">
        <div class="box">
          <p class="p1">企业认证</p>
          <p class="p2">在这里添加、修改、认证企业信息</p>
          <div class="box_top">
            <p class="p3" @click="jin_tianjia" style="color:#fff;background:#8d52e3;border:none;">添加认证企业</p>
            <div class="div1">
              <p class="p6" @click="jiesan">解散认证企业</p>
              <p class="p4" @click="shanchu">删除选中企业</p>
              <p class="p5" @click="bianji">编辑选中企业</p>
            </div>
          </div>
          <div class="div2">
            <!-- <div class="div2_top">
              <p>企业名称</p>
              <p>企业认证</p>
            </div> -->
            <ul class="div2_top">
              <li>
                <p>企业名称</p>
                <p>缴费方式</p>
                <p>节点数量</p>
                <p>有效天数</p>
                <p>认证状态</p>
              </li>
            </ul>
            <ul class="ul">
              <li v-for="(i,index) in list" :key="index" @click="dian_list(index)" :class="{ 'bei':index == inde}" ref="have">
                <p class="li_p">{{i.ent_name}}</p>
                <p>{{jiaofei_zhuanhua(i.pay_type)}}</p>
                <p>{{i.node_num}}</p>
                <p :class="i.valid_term==undefined||i.valid_term.length==0||i.valid_term==0?'red':''">{{i.valid_term!=undefined&&i.valid_term.length!=0?i.valid_term+'天':'0天'}}</p>
                <p :class="i.aut_status=='已认证'?'li_p1':'li_p2'">{{i.aut_status}}</p>
              </li>
            </ul>
            <p class="p1" v-if="chu">您还没有任何认证企业，快去添加吧~</p>
          </div>
        </div>
      </div>
      <div class="beijing" v-if="qiye_jiesan" @click="qiye_jiesan=false">
        <div class="beijing_box" @click.stop>
          <p class="beijing_box_tit">确定要解散当前企业吗？</p>
          <div class="beijing_box_foot">
            <span @click="jiesan_qiye">确定</span>
            <span @click="qiye_jiesan=false">取消</span>
          </div>
        </div>
      </div>
      <!-- <el-drawer
        :visible.sync="drawer"
        :modal='false'
        size="30%">
        aaaaa
      </el-drawer> -->
  </div>
</template>

<script>
import heada from '../components/Heade'
import { delete_un_aut_ent, dismiss_aut_ent, get_rel_list, toggle_user_ent, query_user_aut_ent_list } from '../api/api.js'
export default {
  name: 'install',
  data () {
    return {
      user_id: '',
      list: [],
      inde: 0,
      // drawer: false,
      chu: false,
      qiye_jiesan: false
      
    }
  },
  components: { heada },
  watch: {
  },
  created () {
    // this.ceshi()
    if (sessionStorage.getItem('pc_mlbb_xinxi') != null) {
      this.user_id = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_xinxi'))).user_id
    }
  },
  mounted () {
    this.jichu()
  },
  methods: {
    
    // 获取基础信息
    jichu () {
      query_user_aut_ent_list({
        data: {
          user_id: this.user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10093) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          // this.list=JSON.parse(res.data.body.data)
          for (let i = 0; i < date.length; i++) {
            if (date[i].aut_status == 0) {
              date[i].aut_status = '认证中'
            }
            if (date[i].aut_status == 1) {
              date[i].aut_status = '已认证'
            }
            if (date[i].aut_status == 2) {
              date[i].aut_status = '未通过'
            }
          }
          if (date.length == 0) {
            this.chu = true
          } else {
            this.chu = false
          }
          this.list = date
        } else if (res.data.code == 10094) {
        }
      })
      // toggle_user_ent({})
    },
    dian_list (index) {
      this.inde = index
    },
    // // 点击删除
    shanchu () {
      console.log(this.$refs)
      for (let i = 0; i < this.list.length; i++) {
        if (this.$refs.have[i].lastChild.innerHTML == '未通过' && this.$refs.have[i].className == 'bei') {
          console.log(this.list[i])
          
          delete_un_aut_ent({
            data: {
              ent_id: this.list[i].ent_id,
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.list.splice(i, 1)
            } else if (res.data.code == 500) {}
          })
        } else if (this.$refs.have[i].className == 'bei') {
          this.$message({
            message: '您不能删除该企业',
            type: 'error'
          })
        }
      }
    },
    bianji () {
      console.log(this.$refs)
      for (let i = 0; i < this.list.length; i++) {
        if ((this.$refs.have[i].lastChild.innerHTML == '未通过' || this.$refs.have[i].lastChild.innerHTML == '审批中') && this.$refs.have[i].className == 'bei') {
          console.log(this.list[i])
          sessionStorage.setItem('pc_bianji_ing', JSON.stringify('1'))
          sessionStorage.setItem('pc_bianji_ent_id', JSON.stringify(this.list[i].ent_id))
          this.$router.push('/certification')
        } else if (this.$refs.have[i].className == 'bei') {
          this.$message({
            message: '您不能编辑该企业',
            type: 'error'
          })
        }
      }
    },
    // 进添加企业
    jin_tianjia () {
      this.$router.push('/certification')
      sessionStorage.setItem('pc_bianji_ing', JSON.stringify('0'))
    },
    // 解散认证企业
    jiesan () {
      console.log(this.list[this.inde].ent_id)
      for (let i = 0; i < this.list.length; i++) {
        if (this.$refs.have[i].lastChild.innerHTML == '已认证' && this.$refs.have[i].className == 'bei') {
          this.qiye_jiesan = true
        } else if (this.$refs.have[i].className == 'bei') {
          this.$message({
            message: '您不能解散该企业',
            type: 'error'
          })
        }
      }
    },
    jiesan_qiye () {
      dismiss_aut_ent({
        data: {
          user_id: this.$jichuxinxi().user_id,
          ent_id: this.list[this.inde].ent_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (this.list[this.inde].ent_id == this.$ent_id()) {
            // this.$router.push('/')
            // sessionStorage.clear()
            get_rel_list({
              data: {
                user_id: this.$jichuxinxi().user_id
              }
            }).then(rbs => {
              if (rbs.data.code == 10091) {
                const qiye = JSON.parse(rbs.data.body.data)
                if (qiye.ent_flag != undefined) {
                  sessionStorage.setItem('pc_mlbb_ent_flag', JSON.stringify(qiye.ent_flag))
                } else {
                  sessionStorage.setItem('pc_mlbb_ent_flag', JSON.stringify(''))
                }
                if (qiye.staff_info != undefined) {
                  sessionStorage.setItem('pc_mlbb_ent_info', JSON.stringify(qiye.staff_info))
                } else {
                  sessionStorage.setItem('pc_mlbb_ent_info', JSON.stringify(''))
                }
                if (qiye.rel_list != undefined) {
                  sessionStorage.setItem('pc_mlbb_quanxian', JSON.stringify(qiye.rel_list))
                } else {
                  sessionStorage.setItem('pc_mlbb_quanxian', JSON.stringify([]))
                }
                location.reload()
              } else {}
            })
          }
          this.$message({
            message: '解散成功',
            type: 'success'
          })
          this.list.splice(this.inde, 1)
          this.qiye_jiesan = false
        } else if (res.data.code == 500) {}
      })
    },
    // 缴费转化
    jiaofei_zhuanhua (num) {
      if (num == 1) {
        return '试用'
      } else if (num == 2) {
        return '年费'
      } else if (num == 3) {
        return '月租-年缴'
      } else if (num == 4) {
        return '月租-月缴'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.install {
  background-image: #E7E8EB;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  .con{
    width: 100%;
    height: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    background: #E7E8EB;
    .box{
      position: relative;
      top:1.83rem;
      left:50%;
      height:5.88rem;
      width:11.17rem;
      margin-left: -5.54rem;
      padding: 0 0.43rem;
      border-radius: 0.04rem;
      background: #fff;
      padding-bottom: 0.6rem;
      .p1{
        font-size: 0.18rem;
        color:#1A2533;
        font-weight: 500;
        padding-top:0.21rem;
        margin-bottom: 0.06rem;
      }
      .p2{
        font-size: 0.12rem;
        font-weight: 400;
        color:#A9A9A9;
        margin-bottom: 0.12rem;
      }
      .box_top{
        height:0.42rem;
        width:100%;
        background: #E7E8EB;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
          border-radius: 0.02rem;
          border:0.01rem solid rgba(169,169,169,1);
          font-size: 0.12rem;
          color: #888888;
          text-align: center;
          line-height: 0.25rem;
          background: #fff;
        }
        .p3{
          // height:0.25rem;
          // width:0.98rem;
          padding: 0.02rem 0.1rem;
          margin-left:0.22rem;
          cursor:pointer;
        }
        .div1{
          display: flex;
          justify-content: right;
          align-items: center;
          .p4{
            // height:0.25rem;
            // width:1.13rem;
            padding: 0.02rem 0.1rem;
            margin-right:0.08rem;
            cursor:pointer;
          }
          .p6{
            // height:0.25rem;
            // width:1.13rem;
            padding: 0.02rem 0.1rem;
            margin-right:0.08rem;
            cursor:pointer;
          }
          .p5{
            // height:0.25rem;
            // width: 0.98rem;
            padding: 0.02rem 0.1rem;
            margin-right: 0.22rem;
            cursor:pointer;
          }
        }
      }
      .div2{
        margin-top:0.1rem;
        width:100%;
        .div2_top{
          li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.22rem;
            p{
              font-size: 0.12rem;
              color:#1A2533;
              margin: 0 0.22rem;
              margin-top: 0.15rem;
              width: 25%;
              text-align: center;
            }
          }
          
        }
        .p1{
          font-size: 0.12rem;
          color: #888;
          text-align: center;
          margin-top:1.9rem;
        }
        .ul{
          border-top: 0.01rem solid #eee;
          border-bottom: 0.01rem solid #eee;
          margin-top:0.12rem;
          height:4.5rem;
          overflow-y:auto;
          &::-webkit-scrollbar {
              width: 20px;
              height: 8px;
          }
          
          &::-webkit-scrollbar-thumb {
              border-radius: 12px;
              border: 6px solid rgba(0, 0, 0, 0);
              box-shadow: 8px 0 0 #A5ADB7 inset;
          }
          
          &::-webkit-scrollbar-thumb:hover {
              box-shadow: 8px 0 0 #4A4A4A inset;
          }
          li{
            height:0.69rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.22rem;
            font-size: 0.12rem;
            color:#4C4A4D;
            cursor:pointer;
            .li_p1{
              color:#6ABFA7;
            }
            .li_p2{
              color:#F16977;
            }
            p{
              width: 25%;
              text-align: center;
            }
            .red{
              color:red;
            }
          }
          .bei{
            background: #eee;
          }
        }
      }
    }
  }
  .beijing{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index:100;
    background: rgba(0,0,0,0.5);
    .beijing_box{
        height:2.5rem;
        width:4rem;
        background:#fff;
        position: relative;
        top:1.82rem;
        left:50%;
        margin-left: -2rem;
        border-radius: 0.04rem;
        margin-bottom: 1.4rem;
        .beijing_box_tit{
            font-size: 0.2rem;
            color:#1A2533;
            font-weight: bold;
            text-align: center;
            padding: 0.38rem 0;
        }
        .beijing_box_foot{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top:0.37rem;
            padding-bottom:0.35rem ;
            span{
                padding: 0.14rem 0.27rem;
                border-radius: 0.04rem;
                font-size: 0.14rem;
                margin: 0 0.28rem;
                cursor: pointer;
                &:nth-child(1){
                    background: #f96c6c;
                    color:#fff;
                }
                &:nth-child(2){
                    border:0.01rem solid #888;
                    color:#909090;
                }
                
            }
        }
    }
  }
}

@media screen and (max-width: 12rem) {
  .install {
    width:12rem;
  }
}
</style>
